<template>
  <div :key="formOfPayment.uuid">
    <v-card>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1">
            {{ formOfPayment.remoteID }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ formOfPayment.paymentMethod }} - {{ formOfPayment.amount }} ISK</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-row dense>
            <v-col cols="auto">
              <v-btn
                v-if="formOfPayment.paymentMethod === 'giftcard'"
                depressed
                color="primary"
                @click="showSendGiftcardDialog"
                
              >
                {{ $t('send')}} 
                <v-icon
                  right
                >
                  mdi-email
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="formOfPayment.paymentMethod === 'giftcard'"
                depressed
                color="primary"
                :disabled="updating"
                :loading="updatingFunds"
                @click="showQuickActionDialog"
              >
                {{ $t('addAmount') }}
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                v-if="formOfPayment.paymentMethod === 'giftcard'"
                depressed
                color="primary"
                :disabled="updating"
                :loading="updating"
                @click="showQuickUpdateDialog"
              >
                {{ $t('edit') }}
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                v-if="formOfPayment.active"
                outlined
                color="error"
                @click="disableFormOfPayment"
                :disabled="updating"
                :loading="updating"
              >
                {{ $t('disable') }}
              </v-btn>
              <v-btn
                v-else
                outlined
                color="primary"
                @click="enableFormOfPayment"
                :disabled="updating"
                :loading="updating"
              >
                {{ $t('enable') }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                text
                outlined
                link
                target="_blank"
                rel="noopener noreferrer"
                :href="'https://giftcard.salescloud.is?uuid=' + formOfPayment.uuid + '&organization=' + this.organization.uuid"
              >
                {{ $t('viewOnline') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-tabs v-model="tab">
        <v-tab href="#preview">
          {{ $t('preview') }}
        </v-tab>
        <v-tab href="#useage">
          {{ $t('usage') }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items
      v-model="tab"
      class="transparent"
    >
      <v-tab-item value="preview">
        <v-row>
          <v-col cols="12">
            <iframe
              :title="$t('preview')"
              height="800px"
              width="100%"
              seamless
              style="border:none;"
              :src="onlineUrl"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="useage">
        <v-row>
          <v-col cols="12">
            <TransientDash
              :title="$t('posUsageList')"
              namespace="gifcard_usage_list"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
              :config="dashConfig"
            />
          </v-col>
          <v-col cols="12">
            <TransientDash
              :title="$t('onlineUsageList')"
              namespace="giftcards_report"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
              :config="dashConfig"
            />
          </v-col>
          <v-col cols="12">
            <TransientDash
              :title="$t('giftcardTopupsList')"
              namespace="giftcards_top_up_report"
              :start-time-in-millis="startTimeInMillis"
              :end-time-in-millis="endTimeInMillis"
              :config="dashConfig"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import TransientDash from '../dash/TransientDash.vue'

export default {
  name: 'FormOfPaymentProfile',
  props: {
    formOfPayment: {
      type: Object,
      default: () => ({})
    }
  },
	components: {
		TransientDash
	},
  data() {
        return {
            tab: null,
            updating: false,
            updatingFunds: false,
            quickActionDialog: false,
            amount: 0
        }
    },
    computed: {
        profileQuickActions() {
          return []
        },
        onlineUrl() {
            return 'https://giftcard.salescloud.is?uuid=' + this.formOfPayment.uuid + '&organization=' + this.organization.uuid
        },
        organization() {
            return this.$store.state.organization
        },
				startTimeInMillis() {
					return 0
				},
				endTimeInMillis() {
					return Date.now()
				},
				dashConfig() {

					if(this.formOfPayment === null || this.formOfPayment === undefined) {
						return {}
					}

					return {
						giftcardUUID: this.formOfPayment.uuid
					}
				}
    },
  methods: {
    showSendGiftcardDialog() {
      this.$store.commit('updateGenericDialog', {
        'component': 'components/formsOfPayment/SendGiftcardCard', 
        'props': {
          'giftcard': this.formOfPayment
        }
      })
    },
    showQuickUpdateDialog() {
      this.$store.commit('updateDataToMutate', {
        objectToMutate: this.formOfPayment,
        formComponent: 'components/formsOfPayment/UpdateGiftCard',
        saveCallback: this.updateGiftCard,
        title: this.$t('addToGiftCardAmount')
      })
    },
    showQuickActionDialog() {
      this.quickActionDialog = true
      this.$store.commit('updateDataToMutate', {
        objectToMutate: this.formOfPayment,
        formComponent: 'components/formsOfPayment/AddAmountToGiftCard',
        saveCallback: this.addGiftCardFunds,
        title: this.$t('edit')
      })
    },
    hideQuickActionDialog() {
      this.quickActionDialog = false
      this.amount = 0
    },
    updateGiftCard({ expiryMonth, expiryYear }) {
      this.updating = true
      this.$store.dispatch('editGiftCard', { giftCardUUID: this.formOfPayment.uuid, expiryMonth, expiryYear}).then((result) => {
        if (result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('successfullyUpdatedGiftcard')
          })
        }
        else {
          this.$store.commit('updateActionError', {
            message: this.$t('unableToUpdateExpirationDate'),
            subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
          })
        }

        this.$emit('updated')
      }).finally(() => {
        this.updatingFunds = false
      })
    },
    addGiftCardFunds({ uuid, amount }) {
      this.updatingFunds = true

      this.$store.dispatch('addAmountToGiftcard', { giftcardUUID: uuid, amount: amount }).then((result) => {
        if (result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('successfullyUpdatedGiftcardFunds')
          })
        }else {
          this.$store.commit('updateActionError', {
            message: this.$t('unableToUpdateGiftcardFunds'),
            subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
          })
        }

        this.$emit('updated')

      }).finally(() => {
        this.updatingFunds = false
      })
    },
    disableFormOfPayment() {
      this.updating = true
      this.$store.dispatch('disableFormOfPayment', this.formOfPayment.uuid).then((result) => {

        if (result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('successfullyDisabled')
          })
        }else {
          this.$store.commit('updateActionError', {
            message: this.$t('unableToDisableGiftcard'),
            subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
          })
        }
        this.$emit('updated')
      }).finally(() => {
        this.updating = false
      })
    },
    enableFormOfPayment() {
      this.updating = true
      this.$store.dispatch('enableFormOfPayment', this.formOfPayment.uuid).then((result) => {
        if (result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('successfullyEnabled')
          })
        }else {
          this.$store.commit('updateActionError', {
            message: this.$t('unableToEnableGiftcard'),
            subMessage: this.$t('unknownErrorPleaseTryAgainIfErrorPersistsPleaseContactSupport')
          })
        }
        this.$emit('updated')
      }).finally(() => {
        this.updating = false
      })
    }
  }
}
</script>
